module.exports = [{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":650,"linkImagesToOriginal":true,"showCaptions":false,"markdownCaptions":false,"backgroundColor":"white","quality":50,"withWebp":false,"withAvif":false,"tracedSVG":false,"loading":"lazy","decoding":"async","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"gatsbyRemarkPlugins":[{"resolve":"/opt/buildhome/repo/node_modules/gatsby-remark-copy-linked-files","id":"80be8f09-66d9-5d2d-8c6c-e5998de81ba1","name":"gatsby-remark-copy-linked-files","version":"5.24.0","modulePath":"/opt/buildhome/repo/node_modules/gatsby-remark-copy-linked-files/index.js","pluginOptions":{"plugins":[],"destinationDir":"mdassets"},"nodeAPIs":[],"browserAPIs":[],"ssrAPIs":[]},{"resolve":"/opt/buildhome/repo/node_modules/gatsby-remark-images","id":"b7348ae0-baba-5cc2-82f2-071cf941a75e","name":"gatsby-remark-images","version":"6.24.0","modulePath":"/opt/buildhome/repo/node_modules/gatsby-remark-images/index.js","pluginOptions":{"plugins":[],"maxWidth":1200,"showCaptions":true,"markdownCaptions":false,"withWebp":true},"nodeAPIs":["pluginOptionsSchema"],"browserAPIs":["onRouteUpdate"],"ssrAPIs":[]}],"extensions":[".mdx"],"defaultLayouts":{},"lessBabel":false,"remarkPlugins":[],"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"],"root":"/opt/buildhome/repo","commonmark":false,"JSFrontmatterEngine":false,"engines":{}},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":1200,"showCaptions":true,"markdownCaptions":false,"withWebp":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"KevinK.dev","short_name":"KevinK.dev","start_url":"/","background_color":"#000710","theme_color":"#e52b3e","display":"minimal-ui","icon":"src/images/fullbglogo@10x.png","cache_busting_mode":"none","legacy":true,"theme_color_in_head":true,"crossOrigin":"anonymous","include_favicon":true,"cacheDigest":null},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"localeJsonSourceName":"locale","languages":["en","de"],"defaultLanguage":"en","generateDefaultLanguagePage":true,"siteUrl":"https://kevink.dev","i18nextOptions":{"interpolation":{"escapeValue":false},"keySeparator":".","nsSeparator":":"},"pages":[{"matchPath":"/:lang/projects/:urlname","getLanguageFromPath":true,"excludeLanguages":["en","de"]},{"matchPath":"/:lang/blog/:urlname*","getLanguageFromPath":true,"excludeLanguages":["en","de"]}]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
