// extracted by mini-css-extract-plugin
export var screenReader = "Chatbox-module--screenReader--d0ffc";
export var chatbox = "Chatbox-module--chatbox--54c07";
export var openBottom = "Chatbox-module--openBottom--2cfe9";
export var openBoth = "Chatbox-module--openBoth--64bde";
export var openTop = "Chatbox-module--openTop--775c7";
export var chatnotice = "Chatbox-module--chatnotice--96c18";
export var chatmsg = "Chatbox-module--chatmsg--ec4eb";
export var alignRight = "Chatbox-module--alignRight--6e174";
export var name = "Chatbox-module--name--a254d";
export var chatbubble = "Chatbox-module--chatbubble--888fa";
export var chatBlah = "Chatbox-module--chatBlah--6c033";