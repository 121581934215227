import Chatbox from "../../../../src/components/scambox/Chatbox.tsx";
import Chatmsg from "../../../../src/components/scambox/Chatmsg.tsx";
import Chatnotice from "../../../../src/components/scambox/Chatnotice.tsx";
import Chatblah from "../../../../src/components/scambox/Chatblah.tsx";
import * as React from 'react';
export default {
  Chatbox,
  Chatmsg,
  Chatnotice,
  Chatblah,
  React
};